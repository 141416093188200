import moment, { Moment, unitOfTime } from 'moment';

import { IOfficeLocationsSearchCriteria } from './types';
import { YYYY, MM, HH } from './constants';
import { TIME_FORMAT } from '../../constants/common';
import { ClientService } from '../../shared/api/ClientService';
import { convertInputDateToServerTimeString } from '../../utils/helpers';

export const argumentifyOfficeLocationsSearchCriteria = ({
  filterText,
  nearbyLatitude,
  nearbyLongitude,
  includeInactive,
  isActive,
  sorting,
  skipCount,
  maxResultCount,
}: IOfficeLocationsSearchCriteria) =>
  [filterText, nearbyLatitude, nearbyLongitude, includeInactive, isActive, sorting, skipCount, maxResultCount] as const;

export const convertDatesToReadable = (
  dateFrom: Moment | undefined,
  dateTo: Moment | undefined,
  dateFormat: string,
  granularity: unitOfTime.StartOf
) => {
  const from = moment(dateFrom).format(dateFormat);
  if (!dateTo || moment(dateFrom).isSame(dateTo, granularity)) return from;

  const to = moment(dateTo).format(dateFormat);
  return `${from} - ${to}`;
};

export const convertRawDatesToReadable = (dateFrom: string | undefined, dateTo: string | undefined) => {
  return `${dateFrom} - ${dateTo}`;
};

export const convertTimeToReadable = (timeFrom: Moment | undefined, timeTo: Moment | undefined) => {
  const from = moment(timeFrom).format(TIME_FORMAT);
  const to = moment(timeTo).format(TIME_FORMAT);
  return `${from} - ${to}`;
};

export const getYear = (date: Moment) => Number(moment(date).format(YYYY));

export const getMonth = (date: Moment) => Number(moment(date).format(MM));

export const sortByStartDate = (data: ClientService.AgentAvailabilityEntryDto[]) =>
  data?.sort((a, b) => Number(moment(a.startTime).format(HH)) - Number(moment(b.startTime).format(HH)));

export const groupDatesIntoIntervals = (dates: Moment[]) =>
  dates?.reduce(
    (previous: Array<Moment[]>, current) => {
      if (Math.abs(moment(previous[previous.length - 1][1]).diff(moment(current), 'd')) > 1) {
        return [...previous, [current, current]];
      } else {
        previous[previous.length - 1][1] = current;
        return previous;
      }
    },
    [[dates[0], dates[0]]]
  );

export const modifyWeeklyScheduleEntryArrayTimeToUtc = (
  array?: ClientService.WeeklyScheduleEntryDto[] | ClientService.AvailabilityOverrideEntryDto[]
) =>
  array?.map((item) => ({
    ...item,
    rawStartTime: convertInputDateToServerTimeString(item?.startTime),
    rawEndTime: convertInputDateToServerTimeString(item?.endTime),
  }));
