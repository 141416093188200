import React from 'react';

export const MSG_TO_PDF_FEATUREFLAG = process.env.REACT_APP_FEATUREFLAG_MSG_TO_PDF === 'true';
export const BULK_UPDATE_FEATUREFLAG = process.env.REACT_APP_FEATUREFLAG_BULK_UPDATE === 'true';

interface FeatureFlagProps {
  flag: boolean;
  children: React.ReactNode;
}

const FeatureFlag = ({ flag, children }: FeatureFlagProps): JSX.Element => {
  return flag ? <>{children}</> : <></>; // Return an empty fragment if flag is false
};

export default FeatureFlag;
