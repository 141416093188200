import { useCallback, useEffect, useState } from 'react';
import { Form, Input, Divider, Spin } from 'antd';

import WeeklyRow from '../WeeklyRow/WeeklyRow';
import Button from '../../../../../components/Button/Button';

import { modifyWeeklyScheduleEntryArrayTimeToUtc } from '../../../utils';
import genericMessage from '../../../../../utils/genericMessage';
import { WEEKDAYS_OPTIONS } from '../constants';
import { ClientService } from '../../../../../shared/api/ClientService';
import API from '../../../../../utils/api';

import './WeeklySchedule.scss';
import useLocale from '../../../../../hooks/useLocale';

interface IWeeklyScheduleProps {
  userId?: string;
  hideSaveButton?: boolean;
  externalSaveTrigger?: boolean;
  setTab?: () => void;
  scheduleDone?: (val: boolean) => void;
}

function WeeklySchedule(props: IWeeklyScheduleProps) {
  const { TextArea } = Input;

  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [form] = Form.useForm<ClientService.WeeklyScheduleDto>();
  const [initialValues, setInitialValues] = useState<ClientService.WeeklyScheduleDto>();
  const [loading, setLoading] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { t } = useLocale();

  const requestWeeklySchedule = useCallback(async () => {
    setLoading(true);
    const response = props.userId
      ? await API.getWeeklyScheduleByUserId(props.userId).catch(() => setLoading(false))
      : await API.getWeeklySchedule().catch(() => setLoading(false));
    setLoading(false);

    if (response) setInitialValues(response);
  }, [props.userId]);

  const handleSubmit = useCallback(
    async (values: ClientService.WeeklyScheduleDto) => {
      setSubmitting(true);

      const payload = {
        ...values,
        sundayEntries: modifyWeeklyScheduleEntryArrayTimeToUtc(values?.sundayEntries),
        tuesdayEntries: modifyWeeklyScheduleEntryArrayTimeToUtc(values?.tuesdayEntries),
        mondayEntries: modifyWeeklyScheduleEntryArrayTimeToUtc(values?.mondayEntries),
        wednesdayEntries: modifyWeeklyScheduleEntryArrayTimeToUtc(values?.wednesdayEntries),
        thursdayEntries: modifyWeeklyScheduleEntryArrayTimeToUtc(values?.thursdayEntries),
        fridayEntries: modifyWeeklyScheduleEntryArrayTimeToUtc(values?.fridayEntries),
        saturdayEntries: modifyWeeklyScheduleEntryArrayTimeToUtc(values?.saturdayEntries),
      } as ClientService.WeeklyScheduleDto;

      const response = props.userId
        ? await API.upsertWeeklyScheduleByUserId(props.userId, payload).catch(() => setSubmitting(false))
        : await API.upsertWeeklySchedule(payload).catch(() => setSubmitting(false));
      setSubmitting(false);

      setIsSaved(true);
      if (props.scheduleDone) {
        props.scheduleDone(true);
      }

      if (response?.result === ClientService.Result.Successful) {
        if (!props.hideSaveButton) {
          genericMessage.success(t.WEEKLY_AVAILABILITY_IS_UPDATED);
        }

        requestWeeklySchedule();
      }
    },
    [props.userId, requestWeeklySchedule, t.WEEKLY_AVAILABILITY_IS_UPDATED]
  );

  const failSubmit = useCallback(
    async (errorInfo: any) => {
      if (props.scheduleDone) {
        props.scheduleDone(false);
      }
      if (props.setTab) {
        props.setTab();
      }
    },
    [props.setTab, props.scheduleDone]
  );

  useEffect(() => {
    if (!initialValues) requestWeeklySchedule();
  }, [initialValues, requestWeeklySchedule]);

  useEffect(() => {
    if (initialValues) form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  useEffect(() => {
    if (props.externalSaveTrigger === true && !isSaved) {
      form.submit();
    } else if (props.externalSaveTrigger === false && isSaved) {
      setIsSaved(false);
    }
  }, [props.externalSaveTrigger, form, isSaved, setIsSaved]);

  return (
    <Spin spinning={loading || submitting}>
      <div className="WeeklySchedule">
        <div>{t.SET_WEEKLY_HOURS}</div>
        <Divider className="WeeklySchedule__divider" />
        <Form form={form} layout="vertical" onFinish={handleSubmit} onFinishFailed={failSubmit}>
          {WEEKDAYS_OPTIONS.map((item, index) => (
            <WeeklyRow name={item.value} weekday={item.shortName} key={`weekly-row-${String(index)}`} />
          ))}
          <Form.Item label={t.SCHEDULING_NOTES} name="schedulingNote" className="WeeklySchedule__scheduling-note">
            <TextArea rows={3} />
          </Form.Item>
          {!props.hideSaveButton && (
            <Form.Item>
              <Button iconName="Save" kind="primary" className="WeeklySchedule__button" htmlType="submit">
                {t.SAVE}
              </Button>
            </Form.Item>
          )}
        </Form>
      </div>
    </Spin>
  );
}

export default WeeklySchedule;
