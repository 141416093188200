import { useCallback, useEffect, useState } from 'react';
import { Row, Col, Form, Modal, Spin } from 'antd';
import moment, { Moment } from 'moment';

import Calendar from '../../../../../components/Calendar/Calendar';
import Button from '../../../../../components/Button/Button';

import { groupDatesIntoIntervals } from '../../../utils';
import { modifyWeeklyScheduleEntryArrayTimeToUtc } from '../../../utils';
import { FULL_DATE_FORMAT } from '../../../constants';
import WeeklyRow from '../WeeklyRow/WeeklyRow';
import { ClientService } from '../../../../../shared/api/ClientService';
import API from '../../../../../utils/api';
import { convertInputDateToServerDateOnly } from '../../../../../utils/helpers';

import './OverrideModal.scss';
import useLocale from '../../../../../hooks/useLocale';

interface IProps {
  onOk?: () => void;
  onCancel?: () => void;
  data?: ClientService.AgentAvailabilityDto;
  userId?: string;
  useCustomAdd?: boolean;
  customAdd?: (id: ClientService.AvailabilityOverrideDto) => void;
}

interface IForm {
  entries?: ClientService.AvailabilityOverrideEntryDto[] | ClientService.AgentAvailabilityEntryDto[];
  dates?: Moment[];
}

const OverrideModal = ({ data, onOk, onCancel, userId, useCustomAdd, customAdd }: IProps) => {
  const [form] = Form.useForm<IForm>();

  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useLocale();

  const requestWeeklyOverride = useCallback(
    async ({ entries, intervals }) => {
      if (intervals) {
        setLoading(true);
        const UTCenteries = modifyWeeklyScheduleEntryArrayTimeToUtc(entries);
        for (let interval of intervals) {
          const body = {
            startDate: convertInputDateToServerDateOnly(interval[0]),
            endDate: convertInputDateToServerDateOnly(interval[1]),
            entries: UTCenteries,
          } as ClientService.AvailabilityOverrideDto;

          if (useCustomAdd && customAdd) {
            customAdd(body);
          } else {
            userId
              ? await API.addAvailabilityOverrideByUserId(userId, body).catch(() => setLoading(false))
              : await API.addAvailabilityOverride(body).catch(() => setLoading(false));
          }
        }

        setLoading(false);
      }

      if (onOk) onOk();
    },
    [onOk, userId]
  );

  const requestCalendarOverride = useCallback(
    async ({ availabilityEntries, date }) => {
      setLoading(true);

      const body = {
        date: convertInputDateToServerDateOnly(date),
        availabilityEntries: modifyWeeklyScheduleEntryArrayTimeToUtc(availabilityEntries),
      } as ClientService.AgentAvailabilityDto;

      if (useCustomAdd && customAdd) {
        customAdd(body);
      } else {
        await API.updateAgentAvailability(body).catch(() => setLoading(false));
      }

      setLoading(false);
      if (onOk) onOk();
    },
    [onOk]
  );

  const handleSubmit = useCallback(
    async (values: IForm) => {
      const entries = values?.entries?.filter((item) => Boolean(item));
      const dates = values?.dates || [];

      if (data) {
        requestCalendarOverride({ availabilityEntries: entries, date: dates[0] });
      } else {
        const intervals: Moment[][] = groupDatesIntoIntervals(dates);
        requestWeeklyOverride({ entries, intervals });
      }
    },
    [data, requestCalendarOverride, requestWeeklyOverride]
  );

  useEffect(() => {
    if (data) {
      const entries = data?.availabilityEntries?.map(
        (item) =>
          ({
            ...item,
            startTime: item.startTime?.utc(),
            endTime: item.endTime?.utc(),
          } as ClientService.AgentAvailabilityEntryDto)
      );

      form.setFieldsValue({
        entries,
        dates: [data?.date as Moment],
      });
    }
  }, [data, form]);

  return (
    <Modal
      destroyOnClose
      centered
      visible
      title={
        <>
          <div>{t.ADD_OVERRIDE_DATE}</div>
          <div className="OverrideModal__subtitle">{t.SELECT_THE_DATES_YOU_WANT_TO_OVERRIDE}</div>
          <div className="OverrideModal__date">{Boolean(data) && moment(data?.date).format(FULL_DATE_FORMAT)}</div>
        </>
      }
      className="OverrideModal"
      width={790}
      footer={null}
      closable
      onCancel={onCancel}
    >
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" onFinish={handleSubmit} className="OverrideModal__form">
          <>
            <Form.Item name="dates" noStyle>
              {!data && (
                <Calendar
                  cellStyle={{ height: 52, marginRight: 12 }}
                  disabledDate={(date) => date.isBefore(moment(), 'D')}
                  dateStyle={{
                    display: 'flex',
                    flex: 1,
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontWeight: 'bold',
                  }}
                  multiple
                  onMultiSelect={(values) => form.setFieldsValue({ dates: values as Moment[] })}
                  rulerSize="large"
                />
              )}
            </Form.Item>

            <WeeklyRow name="entries" copyable={false} style={{ border: 0 }} />
          </>

          <Row align="middle" justify="end" gutter={20} className="OverrideModal__footer">
            <Col>
              <Form.Item>
                <Button kind="cancel" onClick={onCancel}>
                  {t.CANCEL}
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => {
                  const dates = getFieldValue('dates');
                  return (
                    <Form.Item>
                      <Button kind="primary" htmlType="submit" disabled={!dates || !dates?.length}>
                        {t.SAVE}
                      </Button>
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};

export default OverrideModal;
