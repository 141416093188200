import { useCallback, useEffect, useState } from 'react';
import { Col, Row, Divider, Spin } from 'antd';
import moment from 'moment';

import AddIcon from '../../AddIcon';
import OverrideModal from '../OverrideModal/OverrideModal';
import OverrideRow from './OverrideRow';

import useModal from '../../../../../hooks/useModal';
import { ClientService } from '../../../../../shared/api/ClientService';
import API from '../../../../../utils/api';
import { convertServerDateOnlyToInputDate } from '../../../../../utils/helpers';
import { MAX_PAGE_SIZE } from '../../../../../constants/common';
import { argumentifyOfficeLocationsSearchCriteria } from '../../../utils';

import './Overrides.scss';
import styles from '../../../../../styles/style.module.scss';
import useLocale from '../../../../../hooks/useLocale';
import { useQuery } from 'react-query';
import {
  MEETING_TYPES_QUERY,
  OFFICE_LOCATIONS_QUERY,
  REGIONS_LOCATIONS_QUERY,
} from '../../../../../constants/reactQuery';

interface IOverridesProps {
  userId?: string;
  useCustomAdd?: boolean;
  externalSaveTrigger?: boolean;
  overridesDone?: (val: boolean) => void;
}

const Overrides = (props: IOverridesProps) => {
  const { showModal, closeModal } = useModal();
  const [isOverrideModalVisible, setIsOverrideModalVisible] = useState(false);

  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [overrides, setOverrides] = useState<ClientService.AvailabilityOverrideDto[]>();
  const [addOverrides, setAddOverrides] = useState<ClientService.AvailabilityOverrideDto[]>([]);
  const [overridesLoading, setOverridesLoading] = useState<boolean>(false);
  const { t } = useLocale();

  const { data: meetings } = useQuery([MEETING_TYPES_QUERY], () => API.listMeetingTypes());
  const { data: regions } = useQuery([REGIONS_LOCATIONS_QUERY], () => API.listOfficeRegions());
  const { data: locations } = useQuery([OFFICE_LOCATIONS_QUERY], () =>
    API.officeLocationsGET(
      ...argumentifyOfficeLocationsSearchCriteria({ includeInactive: true, maxResultCount: MAX_PAGE_SIZE })
    )
  );

  const requestOverrides = useCallback(async () => {
    setOverridesLoading(true);
    let response = props.userId
      ? await API.getAvailabilityOverridesByUserId(props.userId).catch(() => setOverridesLoading(false))
      : await API.getAvailabilityOverrides().catch(() => setOverridesLoading(false));
    setOverridesLoading(false);

    if (response) {
      setOverrides(
        response
          ?.map(
            (item: ClientService.AvailabilityOverrideDto) =>
              ({
                ...item,
                startDate: convertServerDateOnlyToInputDate(item?.startDate),
                endDate: convertServerDateOnlyToInputDate(item?.endDate),
              } as ClientService.AvailabilityOverrideDto)
          )
          ?.sort((a, b) => moment(a.startDate).diff(moment(b.startDate), 'd'))
      );

      if (props.useCustomAdd && addOverrides.length === 0) {
        setAddOverrides(response?.sort((a, b) => moment(a.startDate).diff(moment(b.startDate), 'd')));
      }
    }
  }, [addOverrides, props.useCustomAdd, props.userId, setAddOverrides]);

  const isExisting = useCallback((val: string) => {
    return val.includes('-');
  }, []);

  const handleOverrides = useCallback(async () => {
    if (props.userId) {
      await API.addAvailabilityOverridesByUserId(
        props.userId,
        addOverrides?.map(
          (item: ClientService.AvailabilityOverrideDto) =>
            ({
              ...item,
              id: isExisting(item.id || '') ? item.id : undefined,
            } as ClientService.AvailabilityOverrideDto)
        )
      );
    }
    if (props.overridesDone) {
      props.overridesDone(true);
    }
  }, [addOverrides, props.userId, props.overridesDone]);

  const customDelete = useCallback(
    async (id: string) => {
      setAddOverrides((prev) => {
        return prev.filter((override) => override.id !== id);
      });
    },
    [setAddOverrides]
  );

  const customAdd = useCallback(
    async (dto: ClientService.AvailabilityOverrideDto) => {
      dto.id = moment().valueOf().toString();
      if (dto.entries) {
        const iocationList = locations?.items?.filter((item) => item.isActive);

        for (let entry of dto.entries) {
          entry.meetingTypeName = meetings?.find((item) => item.id === entry.meetingTypeId)?.name;
          const isMeetingInPerson =
            meetings?.find((item) => item.id === entry.meetingTypeId)?.enumValue ===
            ClientService.MeetingTypeEnum.InPerson;
          isMeetingInPerson
            ? (entry.locationName = iocationList?.find((item) => item.id === entry.officeLocationId)?.name)
            : (entry.locationName = regions?.find((item) => item.id === entry.officeRegionId)?.name);
        }
      }
      setAddOverrides((prevOverrides) => {
        return [...prevOverrides, dto]?.sort((a, b) => moment(a.startDate).diff(moment(b.startDate), 'd'));
      });
    },
    [setAddOverrides, locations, meetings, regions]
  );

  const handleOverrideModalClose = useCallback(() => {
    setIsOverrideModalVisible(false);
  }, [setIsOverrideModalVisible]);

  const handleAdd = useCallback(() => {
    props.useCustomAdd
      ? setIsOverrideModalVisible(true)
      : showModal(
          <OverrideModal
            onOk={() => {
              closeModal();
              requestOverrides();
            }}
            onCancel={closeModal}
            userId={props.userId}
            useCustomAdd={props.useCustomAdd}
            customAdd={customAdd}
          />
        );
  }, [closeModal, props.useCustomAdd, props.userId, customAdd, requestOverrides, showModal, setIsOverrideModalVisible]);

  useEffect(() => {
    if (!overrides) {
      requestOverrides().then();
    }
  }, [overrides, requestOverrides]);

  useEffect(() => {
    if (props.externalSaveTrigger === true && !isSaved) {
      setIsSaved(true);
      handleOverrides();
    } else if (props.externalSaveTrigger === false && isSaved) {
      setIsSaved(false);
    }
  }, [props.externalSaveTrigger, isSaved, setIsSaved, handleOverrides]);

  useEffect(() => {
    if (props.useCustomAdd) {
      setIsSaved(false);
    }
  }, [addOverrides]);

  return (
    <Spin className="Overrides" spinning={overridesLoading}>
      <Row justify="space-between">
        <Col>{t.ADD_OVERRIDE_DATES}</Col>
        <Col>
          <AddIcon fill={styles.colorDanger} onClick={handleAdd} />
        </Col>
      </Row>
      <Divider className="Overrides__divider" />

      {(props.useCustomAdd ? addOverrides : overrides)?.map((overrideItem, index) => (
        <OverrideRow
          key={`override-${String(index)}`}
          overrideItem={overrideItem}
          onDeleteSuccess={requestOverrides}
          useCustomDelete={props.useCustomAdd}
          customDelete={customDelete}
        />
      ))}

      {isOverrideModalVisible && (
        <OverrideModal
          onOk={handleOverrideModalClose}
          onCancel={() => setIsOverrideModalVisible(false)}
          userId={props.userId}
          useCustomAdd={props.useCustomAdd}
          customAdd={customAdd}
        />
      )}
    </Spin>
  );
};

export default Overrides;
