import { convertRawDatesToReadable, convertDatesToReadable } from '../../../utils';
import { TIME_FORMAT } from '../../../../../constants/common';

import { ClientService } from '../../../../../shared/api/ClientService';

import './Overrides.scss';

interface IProps {
  entry?: ClientService.AvailabilityOverrideEntryDto;
  useRaw?: boolean;
}

const Entry = ({ entry, useRaw = false }: IProps) => (
  <div className="Overrides__entry-item">
    <div>
      {useRaw
        ? convertRawDatesToReadable(entry?.rawStartTime, entry?.rawEndTime)
        : convertDatesToReadable(entry?.startTime?.utc(), entry?.endTime?.utc(), TIME_FORMAT, 'h')}
    </div>
    <div>{`${entry?.locationName}, ${entry?.meetingTypeName}`}</div>
  </div>
);

export default Entry;
